import { FC } from 'react';
import cn from 'classnames';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

type SuccessToastProps = {
  title?: string;
  detail: string;
  type?: 'embed' | 'default';
  link?: string;
};
const SuccessToast: FC<SuccessToastProps> = ({
  title,
  detail,
  type = 'default',
  link,
}) => {
  return (
    <div
      className={cn(
        'pointer-events-auto w-full overflow-hidden rounded-lg bg-[#10B981] shadow-lg ring-1 ring-black ring-opacity-5',
        {
          ['max-w-sm']: type === 'default',
          ['max-w-[75%] m-auto']: type === 'embed',
        },
      )}
    >
      <div
        className={cn('relative', {
          ['p-4']: type === 'default',
          ['p-3']: type === 'embed',
        })}
      >
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-6 w-6 text-white"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            {title && (
              <p className=" text-base leading-6 font-semibold text-white mb-1">
                {title}
              </p>
            )}
            <p
              className="text-sm leading-5 font-normal text-white"
              data-testid="success-toast-message"
            >
              {detail}
            </p>
            {link && (
              <Link
                href={link}
                className="text-xs font-normal text-white underline mt-2"
              >
                View
              </Link>
            )}
          </div>
        </div>

        {type !== 'embed' && (
          <div className="absolute right-4 top-4">
            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessToast;
