'use client';

import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

import { useSheetComponent } from '@/store/sheet';

type Props = {
  className?: string;
  helpLink: string;
  'data-testid'?: string;
  children?: React.ReactNode;
};

const HelpLinkComponent = ({
  className,
  helpLink,
  'data-testid': testId,
  children,
}: Props) => {
  const [setOpen, setLink, setPath] = useSheetComponent((state) => [
    state.setOpen,
    state.setLink,
    state.setPath,
  ]);

  const handleHelpLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(true);
    setLink(helpLink);
    setPath(window.location.pathname);
  };

  return (
    <button
      onClick={handleHelpLink}
      data-testid={testId}
      className="cursor-pointer text-gray-400 transition-colors hover:text-gray-600"
    >
      {children || (
        <InformationCircleIcon
          className={cn(
            'mb-0.5 ml-1 inline-block h-[18px] w-[18px]',
            className,
          )}
        />
      )}
    </button>
  );
};

export default HelpLinkComponent;
