import cn from 'classnames';
import styles from './loading-dots.module.scss';
import { FC } from 'react';

interface LoadingDotsProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  fill?: string;
}
const LoadingDots: FC<LoadingDotsProps> = ({ size = 24, fill, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={fill}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle className={styles.spinner_b2T7} cx="4" cy="12" r="3" />
      <circle
        className={cn(styles.spinner_b2T7, styles.spinner_YRVV)}
        cx="12"
        cy="12"
        r="3"
      />
      <circle
        className={cn(styles.spinner_b2T7, styles.spinner_c9oY)}
        cx="20"
        cy="12"
        r="3"
      />
    </svg>
  );
};

export default LoadingDots;
